@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Signika+Negative:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap");

/* general */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  --buttonFont: "Signika Negative", sans-serif;
  --textFont: "Comfortaa", sans-serif;
  --titleFont: "Merriweather", sans-serif;
  width: 100vw;
}

.modeLightDark {
  position: fixed;
  top: 6.5%;
  left: 2%;
  transform: translateY(-50%);
  height: 40px;
  width: 80px;
  border: 2px solid rgb(185, 130, 93);
  border-radius: 10px;
  font-size: 1.5rem;
  color: rgb(185, 130, 93);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-evenly;
  padding: 5px 0;
  background: #111;
  background: transparent;
  z-index: 9999999;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .modeLightDark {
    top: 7%;
    left: 79%;
    transform: rotate(-90deg);
  }
}

.lightMode {
  color: rgb(95, 72, 56);
  background: #eee;
  border-color: rgb(95, 72, 56);
}

.modeLightIcon {
  animation: lightMode 1s infinite alternate;
  font-size: 1.5rem;
}

@keyframes lightMode {
  100% {
    transform: translateX(5px);
  }
}

.modeDarkIcon {
  animation: darkMode 1s infinite alternate;
  font-size: 1.5rem;
  transform: rotateY(180deg);
}

@keyframes darkMode {
  100% {
    transform: rotateY(180deg) translateX(-5px);
  }
}

.app {
  --primaryColor: #eee;
  --secondaryColor2: rgb(185, 130, 93);
  --secondaryColor: rgb(133, 88, 55);
  --bookBtnColor: rgb(95, 72, 56);
  --bookBtnColor2: #eee;
  --favoriteRoomColor: rgb(44, 26, 18);
  --footerBgColor: rgba(95, 72, 56, 0.2);
  --appBgColor: rgb(10, 10, 10);
  --languageBg: rgba(0, 0, 0, 0.9);
  --navbarBg: rgba(0, 0, 0, 0.85);
  --navbarBg2: rgba(0, 0, 0, 0.98);
  --navbarColor: rgb(133, 88, 55);
  --servicesHomeBg: rgb(15, 15, 15);
  --servicesHomeColor: #bbb;
  --roomDetailBg: #111;
  --equipmentsBg: #eee;
  --bookingBg: transparent;
  --logoBg: #ddd;
  --menu2Color: rgb(185, 130, 93);

  background-color: var(--appBgColor);
  color: var(--primaryColor);
}

.app2 {
  --primaryColor: #111;
  --secondaryColor2: rgb(139, 97, 70);
  --secondaryColor: rgb(133, 88, 55);
  --bookBtnColor: rgb(95, 72, 56);
  --bookBtnColor2: rgb(95, 72, 56);
  --favoriteRoomColor: rgb(44, 26, 18);
  --footerBgColor: rgba(95, 72, 56, 0.2);
  --appBgColor: #eee;
  --languageBg: rgba(255, 255, 255, 0.9);
  --navbarBg: rgba(255, 255, 255, 0.95);
  --navbarBg2: #ccc;
  --navbarColor: rgb(189, 128, 82);
  --servicesHomeBg: #ddd;
  --servicesHomeColor: #333;
  --roomDetailBg: #fff;
  --equipmentsBg: #333;
  --bookingBg: #fff;
  --logoBg: #fff;
  --menu2Color: rgb(133, 88, 55);

  background-color: var(--appBgColor);
  color: var(--primaryColor);
}

.mainSection {
  position: relative;
  min-height: 100vh;
  width: 80vw;
  max-width: 1360px;
  margin: 5vh auto 0;
}

.app .main-nav {
  background-color: #000;
  background-image: url("./images/homeImages/menuBGBlack.jpg");
  /* background-position: center;
  background-size: 130%;
  background-repeat: no-repeat; */
}

.app2 .main-nav {
  background-color: #fff;
  background-image: url("./images/homeImages/menuBGWhite.jpg");
  /* background-position: center;
  background-size: 130%;
  background-repeat: no-repeat; */
}

@media (orientation: portrait) and (max-width: 800px) {
  .mainSection {
    width: 100vw;
  }
}

.main-page {
  position: relative;
  min-height: 100vh;
  width: 90vw;
  max-width: 1360px;
  padding-top: 13vh;
  margin: 0 auto;
}

.app .title {
  text-transform: uppercase;
  font-size: 2rem;
  font-family: "Merriweather", sans-serif;
  text-align: center;
  color: var(--secondaryColor2);
  margin-bottom: 2vh;
  text-shadow: 3px 3px 3px black;
}

.app2 .title {
  text-transform: uppercase;
  font-size: 2rem;
  font-family: "Merriweather", sans-serif;
  text-align: center;
  color: var(--secondaryColor2);
  margin-bottom: 2vh;
  text-shadow: 1px 1px 1px black;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titleBorder {
  width: 150px;
  height: 2px;
  background: var(--primaryColor);
}

.footer {
  position: relative;
}

.footerBg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: url("./images/homeImages/footerBg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 85%;
  filter: saturate(0.1) brightness(0.2);
  z-index: 0;
}

@media (orientation: portrait) and (max-width: 800px) {
  .footerBg {
    background-attachment: fixed;
  }
}

.submenu {
  background: var(--navbarBg);
  border: var(--bookBtnColor) 2px solid;
  border-top: none;
  border-radius: 0 0 10px 10px;
  position: fixed;
  top: 10vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  padding: 1rem 2rem;
  transition: 0.5s;
  display: none;
}

.submenu::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.submenu.show {
  display: block;
  z-index: 999999;
}

.submenu-center {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.submenu-center a {
  color: var(--primaryColor);
  text-decoration: none;
  font-family: var(--buttonFont);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2vh 0;
  font-size: 1rem;
  transition: 0.3s;
}

.submenu-center a:hover {
  color: var(--navbarColor);
}

.submenu-center svg {
  color: var(--bookBtnColor);
  margin-right: 10px;
  font-size: 1rem;
  flex-shrink: 0;
}

.hotelAbout {
  background-image: url(./images/homeImages/hotelImgAbout.jpg);
  background-size: cover;
}

.cracowAbout {
  background-image: url(./images/homeImages/krakow.jpg);
  background-size: cover;
}

.cracowHeader {
  background-image: url(./images/krakow/wawel.jpg);
  background-size: cover;
  background-position: center 20%;
  filter: brightness(0.8);
}

.hotelAboutHeader {
  background-image: url(./images/homeImages/hotelImgAbout.jpg);
  background-size: cover;
  background-position: center;
}

.backgroundDark {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.hotelInNumbers {
  background-image: url(./images/recepcja&bar/BAR\ 002.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.airportTransferHeader {
  background-image: url(./images/inne/airportTransferMobile.jpg);
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
}

.parkingHeader {
  background-image: url(./images/inne/parking2.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.receptionImg {
  background: url("./images/homeImages/RECEPCJA\ 005.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  animation: zoom 20s ease infinite;
  filter: saturate(0);
}

.AllRoomsHeader2 {
  background: url("./images/deluxe/DELUXE.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 30%;
  animation: zoom 15s ease infinite;
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1) translateX(-5%);
  }

  100% {
    transform: scale(1);
  }
}

.error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--buttonFont);
  text-transform: uppercase;
}

.error h2 {
  font-size: 2rem;
  margin-bottom: 10vh;
  text-align: center;
}

.error a {
  color: var(--secondaryColor2);
  font-size: 4rem;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s;
}

.error a:hover {
  font-size: 5rem;
}

.cookieLink {
  color: var(--secondaryColor2);
  margin-left: 10px;
  text-decoration: none;
}
